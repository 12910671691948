import { SiteStateInterface } from "src/store/site/types";

export function setLanguage(state: SiteStateInterface, payload: string) {
  state.language = payload
}

export function setFilters(state: SiteStateInterface, { filterKey, filters }) {
  const updatedFilters = state.filters;
  updatedFilters[filterKey] = filters;
  state.filters = updatedFilters
}

export function setDefaultFilters(state: SiteStateInterface, { filterKey, filters }) {
  const updatedFilters = state.defaultFilters;
  updatedFilters[filterKey] = filters;
  state.defaultFilters = updatedFilters
}

export function setVisibleColumns(state: SiteStateInterface, { filterKey, visibleColumns }) {
  const updatedVisibleColumns = state.visibleColumns;
  updatedVisibleColumns[filterKey] = visibleColumns;
  state.visibleColumns = updatedVisibleColumns
}

export function setDefaultVisibleColumns(state: SiteStateInterface, { filterKey, visibleColumns }) {

  const updatedVisibleColumns = state.defaultVisibleColumns;
  updatedVisibleColumns[filterKey] = visibleColumns;
  state.defaultVisibleColumns = updatedVisibleColumns
}

export function setSortColumns(state: SiteStateInterface, { filterKey, sortColumns }) {
  const updatedSortColumns = state.sortColumns;
  updatedSortColumns[filterKey] = sortColumns;
  state.sortColumns = updatedSortColumns
}

export function setDefaultSortColumns(state: SiteStateInterface, { filterKey, sortColumns }) {

  const updatedSortColumns = state.defaultSortColumns;
  updatedSortColumns[filterKey] = sortColumns;
  state.defaultSortColumns = updatedSortColumns
}
