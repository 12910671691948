// noinspection AllyPlainJsInspection

export default {
  menu: {
    dashboard: "Dashboard",
    users: "Users"
  },
  globals: {
    evidenca: "Evidenca izobraževanj",
    apprenticeship: "Apprenticeship track",
    career_center: "Career center",
    add: "Add",
    search: "Search",
    details: "Details",
    actions: "Actions",
    next: "Next",
    previous: "Previous",
    done: "Done",
    complete: "Complete",
    print: "Print",
    back: "Back",
    no: "No",
    yet: "Yet",
    create: "Create",
    delete: "Delete",
    duplicate: "Duplicate",
    select_for_vote: "Choose this topic",
    export: "Export",
    import: "Import",
    filters: "Filters",
    pass: "Pass",
    fail: "Fail",
  },
  filters: {
    save_current_filter: "Save filter",
    reset_current_filter: "Reset filter",
  },
  print: {
    title: "Apprenticeship completion report",
    has_from: "completed an apprenticeship from",
    to: "until",
    worked: "at",
    agreement_about: "The apprenticeship was agreed on in the apprenticeship agreement number ",
    supervised_by: "The apprenticeship was supervised by: ",
    student_completed: "The student completed",
    hours_of: "hours of practical work and gained the following competences:",
    school_organizer: "School organizer",
    disclosure: "* Additional information required: the school is required to have the following information for the yearly report: number of students in each year and module, list of PHEs, where the students completed their apprenticeships."
  },
  errors: {
    "Unauthenticated.": "Unauthenticated"
  },
  importer: {
    lbl: "Importer",
    import: "Import",
  },
  views: {
    login: {
      login: "Login",
      logout: "Logout",
      email: "Email",
      password: "Password",
      agree: "I agree to anonymous data processing",
      please_agree: "Please agree to anonymous data processing"
    },
    profile: {
      voted_topics: "Voted topics",
      applied_courses: "Applied courses",
    },
    activity_log: {
      lbl: "Activity log",
      created: "Created by",
      updated: "Updated by",
    },
    notes: {
      lbl: "Notes",
      enter: "Enter note",
      add: "Add note",
      created: "Created by",
      updated: "Updated by",
    },
    assignments: {
      lbl: "Assignments",
      enter: "Enter assignment",
      add: "Add assignment",
      enter_log: "Enter assignment log",
      add_log: "Add assignment log",
      created: "Created by",
      updated: "Updated by",
    },
    notifications: {
      lbl: "Notifications",
    },
    layout: {
      show_all: "Show all",
      select_language: "Select language",
      slovenian: "Slovenian",
      english: "English",
    },
    table: {
      config: "Configure table",
      visible_columns: "Visible columns",
      export: "Export",
      filters: "Filters",
      select_filters: "Select filters",
    },
    form: {
      creating_new: "Add new",
      updating: "Updating",
    },
    internship_agreements: {
      sign: "Sign",
      check_answers: "Check answers",
      evaluate: "Evaluate",
      signatures: "Signatures",
      organization_signature: "Organization signature",
      school_signature: "School signature",
      student_signature: "Student signature",
      grade_updated: "Grade updated",
      comment_updated: "Comment updated",
      signed: "Internship agreement marked as signed",
      answer_added: "Answer added",
      answer_updated: "Answer updated",
    }
  },
  statuses: {
    waiting_for_confirmation: {
      lbl: "Waiting for confirmation",
      color: "primary"
    },
    confirmed: {
      lbl: "Confirmed",
      color: "green"
    },
    completed: {
      lbl: "Completed",
      color: "warning"
    },
    pass: {
      lbl: "Pass",
      color: "green"
    },
    fail: {
      lbl: "Fail",
      color: "red"
    },
    no_status: {
      lbl: "No status",
      color: "grey"
    }
  },
  models: {
    tables: {
      schools: "School | Schools",
      internships: "Internship | Internships",
      internship_agreements: "Internship agreement | Internship agreements",
      study_programs: "Study program | Study programs",
      organizations: "Organization | Organizations",
      template_articles: "Template article | Template articles",
      template_questions: "Template question | Template questions",
      career_jobs: "Career job | Career jobs",
      users: "User | Users",
      topics: "Topic | Topics",
      courses: "Course | Courses",
    },
    fields: {
      id: {
        lbl: "ID"
      },
      name: {
        lbl: "Name"
      },
      title: {
        lbl: "Title"
      },
      first_name: {
        lbl: "First name"
      },
      last_name: {
        lbl: "Last name"
      },
      email: {
        lbl: "Email"
      },
      id_number: {
        lbl: "ID number"
      },
      date_start: {
        lbl: "Start date"
      },
      date_end: {
        lbl: "End date"
      },
      date_due: {
        lbl: "Due date"
      },
      grade_final: {
        lbl: "Final grade"
      },
      grade_exam: {
        lbl: "Exam grade"
      },
      grade_organization_mentor: {
        lbl: "Organization mentor grade"
      },
      grade_school_mentor: {
        lbl: "School mentor grade"
      },
      grade_expert_associate: {
        lbl: "Expert associate grade"
      },
      grade_overall: {
        lbl: "Overall grade"
      },
      option: {
        lbl: "Option",
        add: "Add option",
      },
      question: {
        lbl: "Question",
        add: "Add question",
      },
      ects_points: {
        lbl: "ECTS points"
      },
      sum_of_hours: {
        lbl: "SUM of hours"
      },
      document_number: {
        lbl: "Document number"
      },
      description: {
        lbl: "Description"
      },
      content: {
        lbl: "Content"
      },
      created_by: {
        lbl: "Created by",
      },
      updated_by: {
        lbl: "Updated by",
      },
      deleted_by: {
        lbl: "Deleted by",
      },
      created_at: {
        lbl: "Created at",
      },
      updated_at: {
        lbl: "Updated at",
      },
      deleted_at: {
        lbl: "Deleted at",
      },
      organization: {
        lbl: "Organization"
      },
      internship: {
        lbl: "Internship"
      },
      schoolMentor: {
        lbl: "School mentor"
      },
      organizationMentor: {
        lbl: "Organization mentor"
      },
      requirements: {
        lbl: "Requirements"
      },
      offer: {
        lbl: "Offer"
      },
      private: {
        lbl: "Private"
      },
      student: {
        lbl: "Student"
      },
      address1: {
        lbl: "Address 1"
      },
      address2: {
        lbl: "Address 2"
      },
      city: {
        lbl: "City"
      },
      contractor: {
        lbl: "Contractor"
      },
      table_name: {
        lbl: "Table name"
      },
      post: {
        lbl: "Post"
      },
      country: {
        lbl: "Country"
      },
      addresses: {
        lbl: "Address",
        lbl_plural: "Addresses",
      },
      courseEvents: {
        lbl: "Course event",
        lbl_plural: "Course events",
      },
      studyProgram: {
        lbl: "Study program"
      },
      studyModules: {
        lbl: "Study module",
        lbl_plural: "Study modules"
      },
      studyCompetences: {
        lbl: "Study competence",
        lbl_plural: "Study competences"
      },
      studentQuestionnaire: {
        lbl: "Student evaluation question template"
      },
      organizationMentorQuestionnaire: {
        lbl: "Mentor evaluation question template"
      },
      study_competences: {
        lbl: "Study competences"
      },
      careerJobType: {
        lbl: "Job type"
      },
      grades: {
        lbl: "Grades"
      },
      articles: {
        lbl: "Articles"
      },
      notes: {
        lbl: "Notes"
      },
      school: {
        lbl: "School"
      },
      assignments: {
        lbl: "Assignment"
      },
      activities: {
        lbl: "Activities"
      },
      applications: {
        lbl: "Applications"
      },
      files: {
        lbl: "Files"
      },
      primary: {
        lbl: "Primary"
      },
      date_birthday: {
        lbl: "Birthday"
      },
      phone: {
        lbl: "Phone"
      },
      mobile: {
        lbl: "Mobile"
      },
      vat_id: {
        lbl: "VAT ID"
      },
      ssn: {
        lbl: "Social security number"
      },
      profile: {
        lbl: "Profile"
      },
      web_page: {
        lbl: "Web page"
      },
      registration_number: {
        lbl: "Registration number"
      },
      url_facebook: {
        lbl: "Facebook url"
      },
      url_instagram: {
        lbl: "Instagram url"
      },
      url_twitter: {
        lbl: "Facebook url"
      },
      roles: {
        lbl: "Roles"
      },
      remote: {
        lbl: "Remote"
      },
      region: {
        lbl: "Region"
      },
      field_of_work: {
        lbl: "Field of work"
      },
      date_apply: {
        lbl: "Apply due date"
      },
      topic: {
        lbl: "Topic"
      },
      date: {
        lbl: "Date"
      },
      permissions: {
        lbl: "Permission"
      },
      vote: {
        lbl: "Vote"
      },
      online: {
        lbl: "Online"
      },
      total_hours: {
        lbl: "Total hours"
      },
      votes: {
        lbl: "Votes"
      },
      actions: {
        lbl: "Actions"
      }
    }
  }
};
